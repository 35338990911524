.ant-modal-content {
  padding: 0 !important;
}

.ant-modal-title {
  padding-top: 16px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.ant-modal-footer {
  padding-right: 16px !important;
  padding-left: 16px !important;
  padding-bottom: 16px !important;
}

.ant-switch {
  background: #888888 !important;
}

.ant-switch-checked {
  background: #448866 !important;
}

body {
  margin: 0;
  background-color: #888888;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (prefers-color-scheme: light) {
    background-color:#8fbea7;
  }
  @media (prefers-color-scheme: dark) {
    background-color:#000000;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
